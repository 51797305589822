import styled from 'styled-components';
import checkmark from '../../../icons/checkmarkbox.svg';
import { Root } from '@radix-ui/react-checkbox';

export const tooltipStyles = {
  card: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  box: {
    left: '-100%',
    maxWidth: '272px',
    padding: '3px 12px',
    fontSize: '12px',
    lineHeight: '18px',
  },
  text: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
  },
};

export const Form = styled.form`
  .referralId {
    width: 94%;
    margin-bottom: 18px;

    @media (max-width: 767px) {
      width: 100%;
      margin: 0 !important;
    }
  }
`;

export const RadioOption = styled.div`
  display: flex;
  align-items: center;
  color: #0b0e14;
  font-size: 16px;
  line-height: 24px;

  img {
    margin-right: 8px;
  }
`;

export const Text = styled.div`
  margin: 8px 0 34px 0;
  position: relative;
  color: #7a859e;
  font-size: 14px;

  &:before {
    content: "";
    height: 1px;
    background: rgb(208, 214, 230);
    background: radial-gradient(
      circle,
      rgba(208, 214, 230, 1) 21%,
      rgba(196, 199, 207, 1) 100%
    );
    width: 88%;
    position: absolute;
    top: 7px;
    right: 0;
    @media (max-width: 767px) {
      width: 84%;
    }
  }

  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  margin: 0px 0px 20px;
  padding: 0px;
  gap: 24px;
  align-items: center;

  @media (max-width: 767px) {
    flex-direction: column;
    margin: 0;
    align-items: flex-end;
    gap: 0;
  }
`;

export const SubmitButton = styled.button`
  background: #1a5aff;
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  padding: 8px 50px;
  cursor: pointer;
  border: none;
  margin-left: auto;
  width: 200px;
  height: 40px;
`;

export const SelectLabel = styled.div`
  color: #7a859e;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
`;

export const RadioCheck = styled.div`
  display: inline-flex;
  margin-right: 38px;

  input[type="radio"] {
    appearance: none;
    position: relative;
    padding-left: 30px;
  }
`;

export const ProgressBar = styled.div`
  padding: 0 0 24px;
`;

export const ProgressList = styled.ul`
  display: flex;

  .active {
    color: #0b0e14;

    &:before {
      border: 1px solid #0b0e14;
    }
  }

  .done {
    color: #7a859e;

    &:before {
      border: none;
    }

    &:after {
      content: "";
      width: 16px;
      height: 16px;
      position: absolute;
      left: 0;
      top: -3px;
      background: url(${checkmark});
      background-position: -8px -8px;
      background-repeat: no-repeat;
    }
  }
`;
export const ProgressListItem = styled.li`
  position: relative;
  margin-right: 38px;
  padding-left: 28px;
  color: #7a859e;
  font-size: 14px;

  &:before {
    ${({ inactiveStateText }) =>
      inactiveStateText === 1 ? "content: '1';" : "content: '2';"};
    width: 16px;
    height: 16px;
    border: 1px solid #d0d6e6;
    position: absolute;
    border-radius: 16px;
    top: -3px;
    left: 0;
    line-height: 16px;
    text-align: center;
    font-size: 12px;
  }

  @media (max-width: 767px) {
    font-size: 12px;
    margin-right: 20px;
    span {
      display: none;
    }
  }

  @media (min-width: 960px) {
    font-size: 13px;
  }
`;

export const TextAreaLabel = styled.label`
  font-family: Regola Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #7a859e;
`;

export const TextArea = styled.textarea`
  display: block;
  width: 100%;
  height: 80px;
  border: 1px solid #e7eaf3;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px 14px;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #0b0e14;
  margin-top: 5px;

  &:focus {
    outline: none;
  }
  ::placeholder {
    color: #9faac4;
  }

  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
`;

export const TextAreaContainer = styled.div`
  width: 100%;
`;

export const ModalFooter = styled.div`
  padding: 12px 0 0;
  display: flex;
  justify-content: space-between;
`;

export const SelectContainer = styled.div`
  position: relative;
  width: 50%;

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 22px;
  }
`;

export const BackButton = styled.button`
  border: 1px solid #e7eaf3;
  box-sizing: border-box;
  border-radius: 4px;
  background: #fff;
  padding: 8px 20px;
  cursor: pointer;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
`;

export const SuccessWrapper = styled.div`
  width: 100%;
  padding: 16px 0px 32px;
`

export const SuccessIcon = styled.div`
  margin: 0 auto 32px;
  text-align: center;
`;

export const SuccessHeading = styled.h1`
  font-weight: normal;
  font-size: 28px;
  line-height: 40px;
  text-align: center;
  color: #0b0e14;
  padding: 0 20px;
  margin-bottom: 16px;
`;

export const SuccessText = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #7a859e;
  padding: 0 20px;

  a {
    color: #1a5aff;
    display: inline-block;
    margin-left: 8px;
  }
`;

export const ErrorMessage = styled.div`
  width: 100%;
  margin-top: 3px;
  font-size: 12px;
  color: #ff4545;
  position: absolute;
`;

export const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 6px 0px 24px;
  padding: 0 12px;
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const StyledCheckbox = styled(Root)`
  all: unset;
  background-color: white;
  width: 25px;
  height: 25px;
  border: 1px solid #e7eaf3;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${({ checked }) => checked && `
    background-color: #1a5aff;
    color: white;
  `}
`;

export const CheckboxLabel = styled(TextAreaLabel)`
  font-size: 14px;
`;
