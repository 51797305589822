import React from 'react';
import successIcon from 'icons/successIcon.svg';

import { SuccessWrapper, SuccessIcon, SuccessHeading, SuccessText } from '../style';

export function SuccessMessage() {
  return (
    <SuccessWrapper>
      <SuccessIcon>
        <img src={successIcon} alt="" />
      </SuccessIcon>
      <SuccessHeading>
        Thank you for your interest in TrueFi!
      </SuccessHeading>
      <SuccessText>
        A member of our team will review your information and follow
        up shortly with next steps. Further questions may be directed
        to
        <a href="" target="_blank">
          borrowers@trusttoken.com
        </a>
      </SuccessText>
    </SuccessWrapper>
  );
}
