import React from 'react';

import { Modal } from 'components/Modal';
import { SuccessMessage } from './components/SuccessMessage';
import { useResponsiveHelpers } from '../../../hooks/useResponsiveHelpers';

export function BorrowerInquirySuccessModal({ isShown, onRequestClose }) {
  const { windowWidth } = useResponsiveHelpers();
  const useMobileLayout = windowWidth && windowWidth < 601;

  return (
    <Modal
      isShown={isShown}
      header={' '}
      onRequestClose={onRequestClose}
      onModalClose={() => {}}
      customStyles={{
        modal: { width: useMobileLayout ? '100%' : '600px' },
        header: { borderBottom: 'none', padding: '16px 24px' },
        content: { minHeight: '400px' },
      }}
    >
      <SuccessMessage />
    </Modal>
  );
}
