import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

import { BorrowerInquirySuccessModal } from 'components/Modals/BorrowerInquiryModal/success';
import BorrowerMarketing from './index';
import { useModal } from '../../hooks/useModal';

function BorrowerMarketingSubmit() {
    const { isShown, toggle } = useModal();

    useEffect(() => {
        toggle();
    }, []);

    const handleRequestClose = () => {
        const willClose = isShown;
        toggle();
        if (willClose) navigate('/institutions');
    }

    return (
        <>
            <BorrowerInquirySuccessModal isShown={isShown} onRequestClose={handleRequestClose} />
            <BorrowerMarketing />
        </>
    );
}

export default BorrowerMarketingSubmit;

